#body{
  width: 100vw;
  height: 100vh;
}
/* ---------- */
/* ---------- */
#main{
  width: 100%;
  height: 95%;
  display: flex;
}
/* - */
#listArea{
  width: 25%;
  max-height: 100%;
}
/* - */
#emojiArea{
  width: 75%;
  max-height: 100%;
  background-color: rgb(243, 234, 216);
  /* overflow: hidden; */
}
#create_area{
  height: 65vh;
  width: 65vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  background-color: rgb(255, 255, 255);
}
#buttonDiv{
  width: 65vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}
#saveBtn{
  margin-right: 2%;
  margin-left: 2%;
  width:46%;
}
#linkBtn{
  margin-right: 2%;
  margin-left: 2%;
  width:46%;
}

/* - */
.imgStyle{
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
/* - */
.avatar_height img { 
  width: fit-content;
  height: 100%;
}
.avatar_width img { 
  width: 100%;
  height: fit-content;
}
/* - */
.iconImg{
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
/* - */
#coordinateDiv{
  margin-top: 10vh;
  display: block;
  background-color: rgb(255, 255, 255);
  width: 650px;
  height: 650px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* border: solid 1px black; */
}
#absolute{
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#searchResult{
  width: 100px;
  height: 100px;
  position: absolute;
}

#saveMenu{
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: 45vw;
  height: 85vh;
  margin-top: 2vh;
}

.slider{
  width: 35vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5vh;
  height: 10vh;
}

#textAreaDiv{
  width: 35vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  height: 16vh;
}

#valence{
  font-size: 2vh;
}

#arousal{
  font-size: 2vh;
}

#textfieldTitle{
  font-size: 2vh;
}

#explanatoryText{
  width: 35vw;
  height: 12vh;
  font-size: 2vh;
  resize: none;
}

#saveEmoji{
  width: auto;
  height: 20vh;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 1vh;
}

#finishBtnDiv{
  width: 26vw;
  height: 5vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
  display: flex;
}
#finishBtn{
  width: 40%;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
#copyBtn{
  width: 40%;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
}

#useImg{
  width: 100%;
  height: auto;
}

#useImgDiv{
  width: 80vw;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
}